

import img1 from '../images/slider/doveknight.png'

const dataBanner = [
    {
        id: 1,
                name: '“Faith is the assurance of things hoped for, the conviction of things not seen. ”',
        tag: '@Hebrews 11:1'
    },

]

export default dataBanner;