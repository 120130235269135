const menus = [
    {
        id: 2,
        name: '$HOLY SPIRIT',
        links: '/',
    },
    
    {
        id: 5,
        name: 'Tokenomics',
        links: '/tokenomics',
    },
    {
        id: 6,
        name: 'Meta',
        links: '/meta',

    },

    {
        id: 7,
        name: 'Community',
        links: '/community',
    },
    
]

export default menus;